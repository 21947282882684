/**
 * Oplossing om applicaties die gebruik maken van het injection pattern stand-alone te kunnen uitvoeren.
 *
 * Met dit script kunnen applicaties die gebruik maken van het javascript injection pattern geïsoleerd worden gereviewd.
 * Dergelijke applicaties dienen zodanig gebouwd te zijn dat ze niet afhankelijk zijn van overige onderdelen op de pagina.
 * Indien er sprake moet zijn van interactie met overige onderdelen op de pagina dient er gebruik gemaakt te worden van
 * loosely coupled interfaces. (zoals events en observer patterns)
 *
 * Dit script kan worden uitgebreid met extra functionaliteit om het debuggen en testen te vergemakkelijken.
 * Er is momenteel nog geen variant van deze tool beschikbaar die via docker lokaal of in een build pipeline te gebruiken is.
 *
 * Schrijft tevens een CSP meta-tag die representief is voor de CSP die op de OTAP servers als header wordt geschreven.
 * Dit gedrag kan worden uitgeschakeld door het 'no-csp'-attribuut toe te voegen aan de script tag.
 */
import { escapeHtml } from '../../lib/utils';
import { getPolyfillsHtml } from '../../lib/helpers';

/**
 * Retourneert de gebruikte OTA suffix of lege string gegeven een hostname.
 * @param {string} hostname
 */
function findOtaSuffix(hostname) {
    const match = hostname.match(/-(ontw|test|acc)/);
    return match && match[0] || '';
}

/**
 * Bepaal een aantal variabelen op basis van de url
 * indien via GitLab artifacts wordt gebrowsed.
 */
function getArtifactsBrowsingVars() {
    const
        matchProjectBaseUrl = location.pathname.match(/(.+)\/index.x?html?\b/),
        matchRoute = location.search.match(/\broute=([^&]+)/),
        matchSearch = location.search.match(/&.+/);
    return {
        // Gebruik een base url representatief voor CMS pagina met anymatcher.
        basePrefix: location.pathname + '?route=/',
        // Het pad waaronder alle project assets staan.
        projectBaseUrl: matchProjectBaseUrl && matchProjectBaseUrl[1] || '',
        // De actuele route.
        route: matchRoute && matchRoute[1] || '/',
        // Search attributes exclusief route.
        search: matchSearch && matchSearch[0] || ''
    }
}

// Correcte aanroep afdwingen.
const doctype = document.doctype;
if (!doctype || doctype.publicId + doctype.systemId !== '') throw new Error('<!DOCTYPE html> is vereist');
if (document.readyState !== 'loading') throw new Error('Dit script dient synchroon te worden uitgevoerd.');

const
    script = document.currentScript || document.scripts[document.scripts.length - 1], // Ook IE 11 moet nog ondersteund worden helaas...
    // CSP opt-out voor projecten die hier nog niet aan conformeren.
    cspState = script.hasAttribute('no-csp') ? 'No-' : '',
    scriptSrc = script.src,
    sharedAssetsOrigin = scriptSrc.split('/', 3).join('/'),
    commonBaseUrl = sharedAssetsOrigin + '/common',
    otaSuffix = findOtaSuffix(sharedAssetsOrigin),
    inFrame = window !== parent,
    isBrowsingArtifacts = /\/-\/jobs\/\d+\/artifacts\//.test(location.href),
    // Detecteer artifacts browsing o.b.v. GitLab Pages.
    { basePrefix, projectBaseUrl, route, search } = isBrowsingArtifacts
        ? getArtifactsBrowsingVars()
        : {
            // Gebruik een base url representatief voor CMS pagina met anymatcher.
            basePrefix: '/',
            // Het pad waaronder alle project assets staan.
            projectBaseUrl: '',
            // De actuele route.
            route: location.pathname,
            // Search attributes.
            search: location.search
        },
    indexJsUrl = projectBaseUrl + '/index.js',
    path = route.split('/'),
    app = path[1].split('_'),
    entry = app.shift(),
    variant = app.join('_'),
    appBase = (app.length ? escapeHtml(basePrefix + entry + '_' + variant) : '') + '/';

function prepareDataLayer() {
    // Als er al een dataLayer bestaat dan zijn we hier klaar.
    if (global.dataLayer) return;
    // Zo niet, maak een dataLayer die wordt opgepakt door gtm.js indien
    // deze eventueel op een later moment wordt geladen.
    const dl = global.dataLayer = [];
    dl.default = dl; // Maatregel t.b.v. eigenaardige aanroep vanuit Poncho component.
    if (!inFrame) return;
    // Indien we in een iframe zitten, calls forwarden naar de parent.
    // Dit is het geval wanneer de applicatie embedded is in de website.
    const push = dl.push;
    dl.push = function (value) {
        push.call(dl, value);
        parent.postMessage({ name: 'dataLayerPush', value }, '*');
    };
}

// Zorg dat er geen fouten optreden wanneer applicaties calls doen naar dataLayer.push.
prepareDataLayer();

// Via het framed attribute wordt de outer padding opgeheven. (dient door het parent document bepaald te worden)
document.write(`<html lang="nl"${inFrame ? ' framed' : ''}>
<head>
    <meta charset="utf-8">
    <!-- Onderstaande CSP is een benadering van de CSP response header, voor test-doeleinden. -->
    <meta http-equiv="${cspState}Content-Security-Policy" content="
        base-uri 'self' https://www${otaSuffix}.anwb.nl resource://pdf.js/web/;
        block-all-mixed-content;
        form-action https://api${otaSuffix}.anwb.nl;
        img-src 'self' https://*.static${otaSuffix}.anwb.nl https://api${otaSuffix}.anwb.nl;
        default-src 'self' 'report-sample' https://bam.nr-data.net https://bam.eu01.nr-data.net ${sharedAssetsOrigin} resource://pdf.js/;
        connect-src 'self' ${sharedAssetsOrigin} https://api${otaSuffix}.anwb.nl https://bam.nr-data.net https://bam.eu01.nr-data.net 'report-sample';
        frame-src 'self' https://*.static${otaSuffix}.anwb.nl https://*.services${otaSuffix}.anwb.nl;
        ">
    <title>ANWB</title>
    <base href="${appBase}">
    <link rel="preload" as="script" crossorigin href="${indexJsUrl}">
    ${getPolyfillsHtml(commonBaseUrl)}
    <!-- systemjs is vereist voor init-components.js -->
    <script defer src="${commonBaseUrl}/libs/systemjs/s.min.js"></script>
    <script defer src="${commonBaseUrl}/init-components.js"></script>
    ${inFrame ? `<script async src="${commonBaseUrl}/post-height.js"></script>` : ''}
    <link rel="stylesheet" href="${commonBaseUrl}/content.css">
    <!-- een icon is niet echt nodig maar dit zorgt ervoor dat browsers geen 404 responses genereren door /favicon.ico op te vragen -->
    <link rel="icon" type="image/png" href="${commonBaseUrl}/assets/anwb-icon-16.png">
    <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=2.0, user-scalable=yes">
    <meta name="format-detection" content="telephone=no">
</head>
<body>
    <!-- header en data-hook zijn nog vereist voor de huidige fullscreen mode implementatie -->
    <header><nav class="utilities"><div class="logo"><a href="/" title="Home"></a></div></nav></header>
    <main inject-src="${indexJsUrl}#${entry}"${variant ? 'data-variant="' + escapeHtml(variant) + '"' : ''}></main>
</body>
</html>`);
